export const pages = {
  home: {
    title: "pages.home.title",
    subtitle: null,
    description: "pages.home.description",
    image: "home_banner.jpeg",
  },
  booking: {
    title: "pages.booking.title",
    subtitle: null,
    description: "pages.booking.description",
    image: "room_banner.jpeg",
  },
  story: {
    title: "pages.story.title",
    subtitle: null,
    description: "pages.story.description",
    image: "dolasab_beach_2.jpeg",
  },
  activities: {
    title: "pages.activities.title",
    subtitle: "pages.activities.subtitle",
    description: null,
    image: "activities_banner.jpeg",
  },
  photos: {
    title: "pages.photos.title",
    subtitle: null,
    description: "pages.photos.description",
    image: "photos_banner.jpeg",
  },
  contact: {
    title: "pages.contact.title",
    subtitle: "pages.contact.subtitle",
    description: "pages.contact.description",
    image: "contact_banner.jpeg",
  },
  success: {
    title: "pages.success.title",
    subtitle: null,
    description: null,
    image: "activities_banner.jpeg",
  },
  // privacy: {
  //   title: "Confidentialité",
  //   subtitle: null,
  //   description: null,
  //   image: "rules_banner.jpeg",
  // },
  // terms: {
  //   title: "Conditions d'utilisation",
  //   subtitle: null,
  //   description: null,
  //   image: "rules_banner.jpeg",
  // },
  // politic: {
  //   title: "Politique",
  //   subtitle: null,
  //   description: null,
  //   image: "rules_banner.jpeg",
  // },
  error: {
    title: "pages.error.title",
    subtitle: null,
    description: "pages.error.description",
    image: "home_banner.jpeg",
  },
};
