import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./locales/enTranslations.json";
import frTranslations from "./locales/frTranslations.json";
// import esTranslations from "./locales/esTranslations.json";

// the translations
const resources = {
  en: { translation: enTranslations },
  fr: { translation: frTranslations },
};

// Récupérer la langue du localStorage ou utiliser 'fr' par défaut
const language = localStorage.getItem("i18nextLng") || "fr";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

// Écouter les changements de langue et les sauvegarder dans le localStorage
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("i18nextLng", lng);
});

export default i18n;
